<template>
    <div>
        <Navbar :page="`Diagnósticos ${diagnostico && diagnostico.tipo_responsavel === 'fornecedor' && diagnostico.fornecedor && diagnostico.fornecedor.nome ? diagnostico.fornecedor.nome :  diagnostico && diagnostico.tema ? diagnostico.tema : ''}`" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div class="grid grid-cols-12 mb-5">
                <div class="col-span-12 text-right">
                    <router-link type="button" v-if="$store.state.user._id && !$store.state.user.cliente" :to="`/${route}/form/${$route.params.id}`" class="inline-block text-white hover:bg-yellow-500 bg-yellow-400 rounded py-1 px-4 mt-2 mx-2">
                        Editar
                    </router-link>
                    <button
                        @click="restaurar()" 
                        class="text-white hover:bg-red-700 bg-red-600 rounded py-1 px-4 mt-2">
                        Restaurar respostas
                    </button>
                </div>
            </div>
            <div class="bg-gray-200 py-2 px-4 rounded">
                <div class="grid grid-cols-12 gap-2">
                    <div class="col-span-5">
                        <label class="text-left text-xs font-medium text-gray-500 tracking-wider">Questão</label>
                    </div>
                    <div class="col-span-5">
                        <label class="text-left text-xs font-medium text-gray-500 tracking-wider">Resposta</label>
                    </div>
                    <div class="col-span-2">
                        <label class="text-left text-xs font-medium text-gray-500 tracking-wider">Resposta</label>
                    </div>
                </div>
            </div>
            <div v-for="(item, idx) in perguntas" :key="item._id" class="border-b-2 border-gray-200 py-2 px-4 mb-2">
                <div class="grid grid-cols-12 gap-2">
                    <div class="col-span-12 md:col-span-5">
                        <label class="text-left text-sm font-medium text-black"><b>{{ item.ref}} - </b>{{ item.pergunta }}</label>
                    </div>
                    <div class="col-span-12 md:col-span-5">
                        <div class="mt-2 w-full">
                            <label :for="`pergunta-${item.ref}-Sim`" class="inline-flex items-center">
                                <input type="radio" class="form-radio" :for="`pergunta-${item.ref}-Sim`" :name="`pergunta-${item.ref}`" v-model="item.resposta" value="Sim">
                                <span class="ml-1 md:ml-2 text-xs md:text-sm_2">Sim</span>
                            </label>
                            <label :for="`pergunta-${item.ref}-Nao`" class="inline-flex items-center ml-1 md:ml-2">
                                <input type="radio" class="form-radio" :for="`pergunta-${item.ref}-Nao`" :name="`pergunta-${item.ref}`" v-model="item.resposta" value="Não">
                                <span class="ml-1 md:ml-2 text-xs md:text-sm_2">Não</span>
                            </label>
                            <label :for="`pergunta-${item.ref}-Parcialmente`" class="inline-flex items-center ml-1 md:ml-2">
                                <input type="radio" class="form-radio" :for="`pergunta-${item.ref}-Parcialmente`" :name="`pergunta-${item.ref}`" v-model="item.resposta" value="Parcialmente">
                                <span class="ml-1 md:ml-2 text-xs md:text-sm_2">Parcialmente</span>
                            </label>
                            <label :for="`pergunta-${item.ref}-Naoaplica`" class="inline-flex items-center ml-1 md:ml-2">
                                <input type="radio" class="form-radio" :for="`pergunta-${item.ref}-Naoaplica`" :name="`pergunta-${item.ref}`" v-model="item.resposta" value="Não se aplica">
                                <span class="ml-1 md:ml-2 text-xs md:text-sm_2">Não se aplica</span>
                            </label>
                            <label :for="`pergunta-${item.ref}-Outro`" class="inline-flex items-center ml-1 md:ml-2">
                                <input type="radio" class="form-radio" :for="`pergunta-${item.ref}-Outro`" :name="`pergunta-${item.ref}`" v-model="item.resposta" value="Outro">
                                <span class="ml-1 md:ml-2 text-xs md:text-sm_2">Outro</span>
                            </label>
                            <textarea rows="3" type="text" v-if="item.resposta === 'Outro'" v-model="item.outroResposta" placeholder="Resposta" :name="`pergunta-${item.ref}-Outro-Resposta`" class="block mt-2 focus:ring-yellow-400 focus:border-yellow-400 w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-2">
                        <label class="text-left text-xs font-medium text-gray-500 tracking-wider">{{ item.resposta }}</label>
                        <button type="button" @click="addEvidencia(idx)" class="block mt-2 justify-center py-1 px-2 border border-transparent shadow-sm text-xs_3 font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                            Adicionar evidência
                        </button>
                    </div>
                    <div class="col-span-12 md:col-span-12">
                        <table v-if="item.evidencias && item.evidencias.length" class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                                <th scope="col" class="px-2 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">Evidências</th>
                                <th scope="col" class="px-2 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">Opções</th>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(doc, index) in item.evidencias" :key="doc.filename">
                                <td class="px-2 py-2 text-sm whitespace-nowrap">
                                    {{doc.originalname}}
                                </td>
                                <td class="px-2 py-2 whitespace-nowrap">
                                    <a 
                                    type="button"
                                    target="_blank"
                                    :href="`${url_api}/upload?mimetype=${doc.mimetype}&filename=${doc.filename}&folder=empresas`"
                                    class="mr-1 inline-flex justify-center py-1 px-3 border border-transparent shadow-sm text-xs_3 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-400">
                                    Visualizar
                                    </a>
                                    <button @click="excluirEvidencia(idx, index)" class="mr-1 inline-flex justify-center py-1 px-3 border border-transparent shadow-sm text-xs_3 font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-red-400" type="button">Excluir</button>
                                </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-12">
                <div class="col-span-6">
                    <button @click="$router.back()" v-if="$store.state.user._id" type="button" class="inline-flex justify-center mr-2 py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                        Voltar
                    </button>
                    <button v-if="diagnostico && !diagnostico.finalizado && $store.state.user._id" @click="salvar()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-400">
                        Salvar até aqui
                    </button>
                </div>
                <div class="col-span-6 text-right">
                    <button @click="finalizar()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                        Finalizar questionário
                    </button>
                </div>
        </div>
        <modal name="sucessoFinalizar" :adaptive="true" :height="'auto'">
            <div id="idmodal" class="px-3 py-4 overflow-auto h-full md:h-auto">
                <h1 class="font-r font-semibold text-2xl mb-4">Questionário finalizado com sucesso!</h1>
                <button @click="$modal.hide('sucessoFinalizar')" class="text-white hover:bg-gray-600 bg-green-500 rounded py-2 px-12">
                    OK
                </button>
            </div>
        </modal>
        <modal name="addEvidencia" :width="800" :height="'auto'" :adaptive="true" :reset="true">
            <div class="px-3 py-2 overflow-y-auto h-full">
                <h2 class="text-base font-semibold mb-2"> Adicionar documento </h2>
                <vue-dropzone ref="doc" id="doc" class="w-full" :options="dropzoneOptions" v-on:vdropzone-success="update"></vue-dropzone>
            </div>
        </modal>
        </div>
    </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
export default {
    components: {
      vueDropzone,
    },
    data() {
        return {
            route: 'diagnosticos',
            diagnostico: null,
            perguntas: [],
            itemAddEvidencia: null,
            dropzoneOptions: this.$http.getDropzoneConfig(
            `upload`,
            'post',
            {
              maxFiles: 1,
              addRemoveLinks: true,
              capture: true,
              dictDefaultMessage: 'Clique ou arraste o arquivo aqui',
              dictRemoveFile: 'Remover'
            }
            ),
        }
    },
    methods: {
        async start(){
            const id = this.$route.params.id;

            if (id) {
                const req = await this.$http.get(`/get-diagnosticos/${id}`);
                this.diagnostico = req.data.data;
                this.perguntas = req.data.perguntas;
            }
        },
        async salvar(push) {
            await this.$http.put(`/${this.route}/perguntas/edit`, { perguntas: this.perguntas, diagnostico: this.diagnostico });
            if(!push) this.$router.back();
        },
        async restaurar() {
            this.$confirm({
                title: 'Restaurar diagnóstico',
                message: `Deseja restauras as perguntas do diagnóstico ?`,
                button: {
                    no: 'Não',
                    yes: 'Sim',
                },
                callback: async confirm => {
                    if(!confirm) return
                    for(let i = 0; i < this.perguntas.length; i++){
                        const pergunta = this.perguntas[i];
                        pergunta.resposta = "";
                    }
                    await this.$http.put(`/${this.route}/perguntas/edit`, { perguntas: this.perguntas, diagnostico: this.diagnostico });
                    this.diagnostico.respondidas = 0;
                    await this.$http.put(`/${this.route}/`, this.diagnostico);
                    this.start();
                }
            });
        },
        async finalizar() {
            this.$confirm({
                title: 'Finalizar questionário',
                message: `Deseja marcar o questionário como finalizado ?`,
                button: {
                    no: 'Não',
                    yes: 'Sim',
                },
                callback: async confirm => {
                    if(!confirm) return this.$router.back();
                    await this.salvar(true);
                    this.diagnostico.respondido = true;
                    this.diagnostico.finalizado = true;
                    this.diagnostico.status = 'Concluído';
                    const resp = await this.$http.put(this.$store.state.user._id ? `/v1/${this.route}/` : `/${this.route}/`, this.diagnostico);
                    if(!resp.data.success) return  this.$vToastify.error(resp.data.err);
                    this.$vToastify.success("Finalizado com sucesso!");
                    if(this.diagnostico.tipo_responsavel === 'fornecedor') this.$modal.show('sucessoFinalizar');
                    if(this.$store.state.user._id) this.$router.back();
                }
            });
        },
        async update(file, response) {
            if(response.file) this.perguntas[this.idxAddEvidencia].evidencias.push(response.file);
            this.$refs.doc.removeFile(file);
            this.itemAddEvidencia = null;
            this.$modal.hide('addEvidencia');
        },
        excluirEvidencia(idxPergunta, index) {
            if(this.perguntas[idxPergunta] && this.perguntas[idxPergunta].evidencias) this.perguntas[idxPergunta].evidencias.splice(index, 1);
        },
        addEvidencia(idx){
            this.idxAddEvidencia = idx;
            this.$modal.show('addEvidencia');
        }
    },
    async beforeMount() {
        this.start();
    },
}
</script>